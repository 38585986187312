import React from 'react';
import { NavItem } from '../../utils/types';

import './faqs.css';

export interface FAQsProps {
  number?: string;
  headerText?: string;
  headerTextMain?: string;
  style?: React.CSSProperties;
  navItems?: Array<NavItem>;
  onNavItemSelected?: (index: number) => void;
}

const FAQsMembership: React.FC<FAQsProps> = ({
  number,
  headerText = '',
  headerTextMain = 'FAQs',
  style,
  navItems,
  onNavItemSelected
}) => {
  return (
    <div id="FAQs" style={style}>
      <div className="FAQs-content">
        <div className="FAQs-inner-content">
          <div className="FAQs-title">
            <div className="FAQs-title__content">
              <h1 className="FAQs-title__content-main">{headerTextMain}</h1>
            </div>
          </div>
          <div className="FAQs-details">
            {/* <strong>HOW DO I CANCEL?</strong>
            <p>Please visit our <a href="https://finswash.com/manage-membership">Manage Membership</a> page to cancel your wash club membership.</p>
          */}
            <br />
            <strong>
              WHAT IF I DO NOT HAVE MY LICENSE PLATE # OR BARCODE?
            </strong>
            Providing your license plate # or barcode will allow for faster
            processing. Please fill out the form above as completely as you can.
            The Fins Wash Club account holder&apos;s name and phone number will
            allow us to look up your account or reach out for more information.
            <br />
            <br />
            <strong>HOW LONG DOES A CANCELLATION TAKE?</strong>
            Please allow up to 48 hours for processing of your cancellation.
            <br />
            <br />
            <strong>CAN I PAUSE MY MEMBERSHIP?</strong>
            <p>
              To pause your membership, please reach out to{' '}
              <a href="mailto:info@finswash.com">info@finswash.com</a> with your
              wash club information and request to pause your wash club
              membership.
            </p>
            <strong>HELPFUL TIPS</strong>
            <ul>
              <li style={{ marginBottom: 0 }}>
                Fins Wash Club membership valid at any FINS location.
              </li>
              <li>Each membership allows unlimited washing of one car.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQsMembership;
